<template>
     <div class="container">
		<div class="row">
			<div class="col-12">
				<div class="sign__content">
               <form @submit.prevent="onLogin()" class="sign__form vld-parent">
                  <router-link to="/" class="sign__logo">
                     <img src="static/img/logoSvg.svg" alt="">
                  </router-link>
						<div class="sign__group">
                     <span class="sign__delimiter">رقم الهاتف </span>
                     <input type="phone" v-model="msisdn" class="sign__input input" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = '09xxxxxxxx'">
						</div>
                  <div class="sign__group">
                     <!-- <button class="sign__btn" type="submit">تسجيل دخول</button> -->
                     <button class="sign__btn button" style="color:#fff">
                        <span v-if="loading" class="px-1 ">جاري تسجيل الدخول</span> &nbsp;
                        <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                        <span v-else class="arabickufi">تسجيل الدخول</span>
                     </button>
                     <span class="sign__delimiter"><h6></h6></span>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { useToast } from "vue-toastification";
export default {
   name: 'AppLogin',
   data() {
      return {
         msisdn: "",
         loading: false,
      };
   },
   mounted() {
       if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
       }
    },
   methods: {
      async onLogin() {
         const toast = useToast();
         //  let input = document.querySelector(".input");
         //  let button = document.querySelector(".button");
         //  button.disabled = true;
         //  input.addEventListener("change", stateHandle);

         //  function stateHandle() {
         //    if(document.querySelector(".input").value === "") {
         //       button.disabled = true;
         //    } else {
         //       button.disabled = false;
         //    }
         //  }
          if (this.msisdn != "") {
               var phoneno = (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/);
               if (this.msisdn.match(phoneno)) {
                  if (this.msisdn.startsWith("09")) {
                     let phone = this.msisdn.substr(1);
                     this.loading = true
                     await HTTPDSP.post("DSPCheckLogin.php?msisdn=249"+phone).then((res) => {
                            if (res.data.status == 1 && res.data.remming_minutes > 0) {
                                this.$cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", { timeout: 2000 });
                                setTimeout(() => this.$router.push({ path: "/" }), 2500);
                            } else if (res.data.status == 1 && res.data.remming_minutes < 0) {
                                toast.success("تم تسجيل الدخول", { timeout: 2500, });
                                this.$cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", { timeout: 2000 });
                            } else if (res.data.status == 0) {
                                toast.warning("عفوا انت لست مشترك في هذة المنصة", { timeout: 2000, });
                                window.open("https://dsplp.sd.zain.com/?p=2416749956", "_self");

                              //   setTimeout(() => { window.open("http://test.zaindsp.com:3033/?p="+res.data.product_code); }, 2500);
                              
                            }else {
                                toast.info("عفوا انت لست مشترك في الخدمة " , { timeout: 2000 });
                                window.open("https://dsplp.sd.zain.com/?p=2416749956", "_self");

                              //   setTimeout(() => { window.open("http://test.zaindsp.com:3033/?p="+res.data.product_code); }, 2500);
                              //   window.open("http://test.zaindsp.com:3033/?p="+res.data.product_code);	
                            }
                        }).catch((error) => {
                           console.log(error);
                        }).finally(() => {
                           this.loading = false;
                        });
                  } else {
                     toast.info("رقم الهاتف ليس زين" , { timeout: 2000 });
                  }
               } else {
                  toast.info("رقم الهاتف يجب ان يتكون من 10 رقم ويبدا بي 09 " , { timeout: 2000 });
               }
            } else {
               toast.info("الرجال ادخال رقم الهاتف" , { timeout: 2000 });
            }
      }
    },
}
</script>

<style>

</style>